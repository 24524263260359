<template>
  <v-container>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="timelogs"
      :items-per-page="15"
      class="elevation-1"
      mobile-breakpoint="0"
      :loading="loading"
    >
      <template v-slot:[`item.subscription_end`]="{ item }">
        <div :class="getStyle(item.subscription_end)">{{ item.subscription_end }}</div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PlayerStats",

  computed: {
    ...mapGetters("timelogs", ["timelogs", "loading"]),
  },

  methods: {
    ...mapActions("timelogs", ["getTL"]),

    getStyle (subscription_end) {
      return subscription_end == '15.02.2021' ? 'red--text font-weight-bold'
       : 'font-weight-bold';
    }
  },

  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "Sessions", align: "center", value: "sessions" },
      { text: "Session Average", align: "center", value: "average" },
      { text: "Time online", align: "center", value: "total" },
      { text: "Kills", align: "center", value: "kills" },
      { text: "Assists", align: "center", value: "assists" },
      { text: "Deaths", align: "center", value: "deaths" },
      {
        align: "center",
        value: "headshots",
        class: "headshot",
        sortable: false,
      },
      { text: "Subscription ends", align: "center", value: "subscription_end" },
    ],
  }),

  mounted() {
    this.getTL();
  },
};
</script>

<style lang="scss">
.headshot {
  &:before {
    content: url("../assets/headshot.svg");
    color: #fff;
    width: 25px;
    height: 25px;
    display: inline-block;
  }

  .theme--dark & {
    &:before {
      content: url("../assets/headshot-white.svg");
    }
  }
}
</style>